import { useEffect } from 'react';
import { PropertyType } from '@front/ui';
import { CustomDirectionOptionId } from '@lib/ia/src/sort/types';
import { useLocalesInfo, useMyProfile } from '@lib/web/apis';

import { useSort } from './useSort';

export default function useClubDefaultSort() {
  const { setCriteria, resetSort } = useSort();
  const { data: profileRes } = useMyProfile();
  const profile = profileRes?.data;
  const { data: localeRes } = useLocalesInfo();

  useEffect(() => {
    const locales = localeRes?.data || [];
    setCriteria([
      {
        id: 'bestMatches',
        field: {
          label: 'Priority',
          name: 'priority',
          type: PropertyType.Select,
          customDirectionOptions: [
            {
              value: 'customDir_bestMatches',
              label: 'Best Matches',
              direction: 'desc',
            },
          ],
        },
        direction: 'customDir_bestMatches',
        disabled: true,
        viewOnly: true,
      },
      ...(profile && locales
        ? [
            {
              id: 'locale',
              field: {
                label: 'User Locale',
                name: 'priorityLocale',
                type: PropertyType.Select,
                customIcon: 'OtherMultiselect',
                customDirectionOptions: locales.map((locale) => ({
                  type: 'rawQuery' as const,
                  value: `customDir_${locale.id}` as CustomDirectionOptionId,
                  label: locale.name,
                  rawQueryParams: {
                    search: `priorityLocale:${locale.id}`,
                    searchFields: 'priorityLocale:in',
                  },
                })),
              },
              direction:
                `customDir_${profile.userLocale.id}` as CustomDirectionOptionId,
            },
          ]
        : []),
      {
        id: 'officialType',
        field: {
          label: 'Club Type',
          name: 'officialType',
          type: PropertyType.Status,
          customDirectionOptions: [
            {
              value: 'customDir_official',
              label: 'Official',
              direction: 'desc',
            },
            {
              value: 'customDir_nonOfficial',
              label: 'Non-Official',
              direction: 'asc',
            },
          ],
        },
        direction: 'customDir_official',
      },
      {
        id: 'clubMember',
        field: {
          label: 'Club Members',
          name: 'clubMemberCount',
          type: PropertyType.Number,
        },
        direction: 'desc',
      },
      {
        id: 'clubName',
        field: {
          label: 'Club Name',
          name: 'clubName',
          type: PropertyType.ShortText,
        },
        direction: 'asc',
      },
    ]);
  }, [localeRes, profile, setCriteria]);

  useEffect(() => {
    return () => {
      resetSort();
    };
  }, [resetSort]);
}

import { useCallback } from 'react';
import { alpha, Box, Chip, Theme, useMediaQuery } from '@mui/material';
import { OtherError as OtherErrorIcon } from '@front/icon';
import { Icon, LoadingIcon, Scrollbar, SimpleTooltip } from '@front/ui';

import { useFilter } from '../../hooks/utils/useFilter';
import { useSort } from '../../hooks/utils/useSort';
import { FilterType } from '../../types/filter';
import { SortType } from '../../widgets/CommonPanels/SortPanel/types';

import { FilterChipItem } from './types';

const styles = {
  root: {
    display: 'flex',
    gap: 1,
    width: '100%',
    '& .MuiChip-label': {
      px: '12px',
    },
  },
  chipWithIcon: {
    '& .MuiChip-label': {
      pl: '11px',
    },
    '& .MuiChip-icon': {
      ml: '12px',
    },
    '&.MuiChip-outlined': {
      boxShadow: (theme: Theme) =>
        `inset 0 0 0 1px ${alpha(theme.palette.text.primary, 0.3)}`,
    },
  },
  chipWithCustomIcon: {
    '&.MuiChip-outlined': {
      boxShadow: (theme: Theme) =>
        `inset 0 0 0 1px ${alpha(theme.palette.text.primary, 0.1)}`,
    },
  },
  chipError: {
    bgcolor: 'error.dark',
  },
  chipIconWrapper: {
    width: 16,
    height: 16,
  },
};

function getIcon(
  icon?: FilterChipItem['icon'],
  loading?: boolean,
  error?: boolean
) {
  if (error) {
    return (
      <Box sx={styles.chipIconWrapper}>
        <OtherErrorIcon width={16} height={16} />
      </Box>
    );
  }
  if (loading) {
    return (
      <Box sx={styles.chipIconWrapper}>
        <LoadingIcon />
      </Box>
    );
  }
  if (typeof icon === 'string') {
    return <Icon name={icon} width={16} height={16} />;
  }
  return icon;
}

type ChipItemProps = {
  label?: string;
  icon?: FilterChipItem['icon'];
  tooltip?: string;
  selected?: boolean;
  disabled?: boolean;
  loading?: boolean;
  error?: boolean;
  onClick?: () => void;
};

function ChipItem({
  label,
  icon,
  tooltip,
  loading = false,
  selected = false,
  disabled = false,
  error = false,
  onClick,
}: ChipItemProps) {
  const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'));

  const isLoading = selected && loading;
  const isError = selected && error;
  const withIcon = !!icon || isLoading || isError;
  const withCustomIcon = !!icon && typeof icon !== 'string';
  const chip = (
    <Chip
      label={label}
      variant={selected ? 'filled' : 'outlined'}
      clickable
      disabled={disabled}
      onClick={onClick}
      icon={getIcon(icon, isLoading, isError)}
      sx={[
        isError && styles.chipError,
        withIcon && styles.chipWithIcon,
        withCustomIcon && styles.chipWithCustomIcon,
      ]}
    />
  );

  if (mdUp && tooltip) {
    return <SimpleTooltip title={tooltip}>{chip}</SimpleTooltip>;
  }
  return chip;
}

type QuickFilterChipsProps = {
  filterType: FilterType;
  sortType?: SortType;
  items: (FilterChipItem | false)[];
  allItem?: {
    title?: string;
    icon?: FilterChipItem['icon'];
    tooltip?: string;
  };
  state?: 'default' | 'loading' | 'error';
};

export default function QuickFilterChips({
  filterType,
  sortType,
  items,
  allItem,
  state = 'default',
}: QuickFilterChipsProps) {
  const chipItems = items.filter((item) => !!item) as FilterChipItem[];

  const { removeConditionByField, replaceCondition, isConditionExist } =
    useFilter();

  const { removeCriteriaByField, replaceCriteria, isCriteriaExist } = useSort();

  const activeFilters = chipItems.filter((item) => {
    return item.conditions.every((condition) =>
      isConditionExist(
        condition.fieldName,
        condition.operator,
        condition.values
      )
    );
  });

  const activeSortItems = chipItems.filter((item) => {
    return item.sort?.every((criteria) =>
      isCriteriaExist(criteria.fieldName, criteria.direction)
    );
  });

  const isActive = useCallback(
    (chipItem: FilterChipItem) => {
      return activeFilters.some((item) => item.id === chipItem.id);
    },
    [activeFilters]
  );

  const clearAll = () => {
    // remove all activated filter conditions
    activeFilters.forEach((item) => {
      item.conditions.forEach((condition) => {
        removeConditionByField(condition.fieldName);
      });
    });
    // remove all activated sort criteria
    activeSortItems.forEach((item) => {
      item.sort?.forEach((criteria) => {
        removeCriteriaByField(criteria.fieldName);
      });
    });
  };

  const handleChipClick = (item: FilterChipItem) => {
    clearAll();
    item.conditions.forEach((condition) => {
      replaceCondition(
        filterType,
        condition.fieldName,
        condition.operator,
        condition.values
      );
    });
    if (sortType) {
      item.sort?.forEach((criteria) => {
        replaceCriteria(sortType, criteria.fieldName, criteria.direction);
      });
    }
  };

  const noFilter = activeFilters.length === 0;

  return (
    <Scrollbar>
      <Box sx={styles.root}>
        <ChipItem
          label={allItem?.title || 'All'}
          icon={allItem?.icon}
          tooltip={allItem?.tooltip}
          selected={noFilter}
          loading={state === 'loading'}
          error={state === 'error'}
          onClick={clearAll}
        />
        {chipItems.map((item, index) => (
          <ChipItem
            key={index}
            label={item.label}
            icon={item.icon}
            tooltip={item.tooltip}
            disabled={item.disabled}
            selected={isActive(item)}
            loading={state === 'loading'}
            error={state === 'error'}
            onClick={() => handleChipClick(item)}
          />
        ))}
      </Box>
    </Scrollbar>
  );
}

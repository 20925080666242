import { useDeferredValue, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import type { GetStaticProps, NextPage } from 'next';
import Router from 'next/router';
import { Box, Typography } from '@mui/material';
import SEOHeader from '@app/web/src/components/SEOHeader';
import MainLayout from '@app/web/src/layouts/MainLayout';
import RootLayout from '@app/web/src/layouts/RootLayout';
import { appConfig } from '@front/config';
import { MainApps as MainAppsIcon } from '@front/icon';
import { BaseLayoutScrollContainer, SquareAvatar } from '@front/ui';
import IaActionContextProvider from '@lib/ia/src/core/IaAction/IaActionProvider';
import {
  CardCornerAvatarEvent,
  CardLayoutItem,
} from '@lib/ia/src/layouts/CardLayout/types';
import IaLayouts from '@lib/ia/src/layouts/IaLayouts';
import combineSort from '@lib/ia/src/utils/combineSort';
import { ClubOfficialType, ClubViewSlug, useIaClubsList } from '@lib/web/apis';
import { FloatingProfile, ResponsivePageTitle } from '@lib/web/ui';
import { isEqual } from 'lodash';

import QuickFilterChips from '../src/components/QuickFilterChips';
import { FilterChipItem } from '../src/components/QuickFilterChips/types';
import useCardLayoutCreationState from '../src/hooks/utils/useCardLayoutCreationState';
import useClubDefaultSort from '../src/hooks/utils/useClubDefaultSort';
import { useSearchFilterSort } from '../src/hooks/utils/useSearchFilterSort';
import { useIaClubCtaAvailableActions } from '../src/ia/club/iaClubCta';
import useIaClubCardItem from '../src/ia/club/useIaClubCardItem';
import ClubToolbar from '../src/widgets/ClubsPage/ClubToolbar';

type Props = {
  slug: string;
};

export const getStaticProps: GetStaticProps<Props> = async (context) => ({
  props: {
    slug: (context.params?.slug || '') as string,
  },
});

const styles = {
  root: {
    pt: { xs: 2, sm: 1 },
    pb: 5,
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  result: {
    opacity: 0.75,
  },
  action: {
    display: { md: 'flex' },
    justifyContent: { md: 'flex-end' },
    alignItems: { md: 'flex-end' },
    '& .MuiButtonBase-root': {
      width: { xs: '100%', md: 'auto' },
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    gap: 3,
  },
  chipIconText: {
    fontSize: 4.8,
    whiteSpace: 'pre-line',
    lineHeight: '5px',
    color: 'text.primary',
  },
};

const Home: NextPage<Props> = () => {
  const { t } = useTranslation('club');
  const { keyword, sort, filter, rawQueryParams } = useSearchFilterSort('club');
  const [creatorAnchorEl, setCreatorAnchorEl] = useState<Element | null>();
  const [currentCardItem, setCurrentCardItem] = useState<CardLayoutItem>();
  void useClubDefaultSort();

  const filterDeferred = useDeferredValue(filter);
  const isFilterStale = !isEqual(filter, filterDeferred);

  const { dataset, isLoadingInitialData, mutate, error } = useIaClubsList({
    viewSlug: ClubViewSlug.CardCenterDefault,
    ...filterDeferred,
    ...combineSort(sort),
    keyword,
    rawQueryParams,
  });

  const isLoading = isFilterStale || isLoadingInitialData;
  const isError = !!error;

  const { mapToCardItem, getEmptyAction } = useIaClubCardItem();
  const { state: creationState, onDismiss: onCreationErrorDismiss } =
    useCardLayoutCreationState('/club/form/overview');

  const layouts = useMemo(() => {
    if (isLoading)
      return [
        {
          layout: 'card-layout-skeleton' as const,
          settings: {
            columns: [1, 2, 3, 4, 6],
            cardHeight: 220,
          },
        },
      ];
    const items = dataset.map<CardLayoutItem>(mapToCardItem);

    if (items.length === 0 && !isError) {
      const emptyAction = getEmptyAction(creationState);
      items.push(emptyAction);
    }

    return [
      {
        layout: 'card-layout' as const,
        settings: {
          columns: [1, 2, 3, 4, 6],
          descriptionLines: 2,
          avatarSize: 40,
          emptyText: !isError
            ? t('No clubs')
            : t(
                'cardLayout.error.message',
                'Unable to load clubs. Please refresh the page or try again later.'
              ),
          emptyIcon: 'NFTEmojiCryingFace',
          optimizeRenderEnabled: true,
        },
        groups: [
          {
            id: 'items',
            title: !isError ? t('## clubs', { count: dataset.length }) : '',
            items,
          },
        ],
      },
    ];
  }, [
    t,
    isLoading,
    isError,
    dataset,
    creationState,
    mapToCardItem,
    getEmptyAction,
  ]);

  const availableActions = useIaClubCtaAvailableActions({
    onJoined: (target: CardLayoutItem) => {
      mutate();
      Router.push(`/club/${target.id}/start`);
    },
    onAccepted: (target: CardLayoutItem) => {
      mutate();
      Router.push(`/club/${target.id}/start`);
    },
    onRequested: () => {
      mutate();
    },
    onCanceled: () => {
      mutate();
    },
    cornerAvatarActions: {
      onMouseEnter: (event: CardCornerAvatarEvent) => {
        setCreatorAnchorEl(event.anchorEl);
        setCurrentCardItem(event.target);
      },
    },
    onCreationErrorDismiss,
  });

  const filterChips: (FilterChipItem | false)[] = [
    {
      id: 'sat',
      label: 'SAT',
      tooltip: t('filterChips.sat.tooltip', 'Show SAT clubs'),
      icon: (
        <SquareAvatar size={16} bgcolor="#FF7401">
          <Typography sx={styles.chipIconText}>SAT</Typography>
        </SquareAvatar>
      ),
      conditions: [
        {
          fieldName: 'clubName',
          operator: 'StartsWith',
          values: 'SAT',
        },
        {
          fieldName: 'clubCreator:clubCreatorSourceId',
          operator: 'Contains',
          values: [
            {
              label: 'Aha',
              value: appConfig.ahaUserId,
              avatarUrl: '/logo.png',
            },
          ],
        },
        {
          fieldName: 'officialType',
          operator: 'IsIn',
          values: [
            {
              label: 'Official',
              value: ClubOfficialType.Official,
            },
          ],
        },
      ],
    },
    {
      id: 'act',
      label: 'ACT',
      tooltip: t('filterChips.act.tooltip', 'Show ACT clubs'),
      icon: (
        <SquareAvatar size={16} bgcolor="primary.light">
          <Typography sx={styles.chipIconText}>ACT</Typography>
        </SquareAvatar>
      ),
      conditions: [
        {
          fieldName: 'clubName',
          operator: 'StartsWith',
          values: 'ACT',
        },
        {
          fieldName: 'clubCreator:clubCreatorSourceId',
          operator: 'Contains',
          values: [
            {
              label: 'Aha',
              value: appConfig.ahaUserId,
              avatarUrl: '/logo.png',
            },
          ],
        },
        {
          fieldName: 'officialType',
          operator: 'IsIn',
          values: [
            {
              label: 'Official',
              value: ClubOfficialType.Official,
            },
          ],
        },
      ],
    },
    {
      id: 'ap',
      label: 'AP',
      tooltip: t('filterChips.ap.tooltip', 'Show AP clubs'),
      icon: (
        <SquareAvatar size={16} bgcolor="#7935CB">
          <Typography sx={styles.chipIconText}>AP</Typography>
        </SquareAvatar>
      ),
      conditions: [
        {
          fieldName: 'clubName',
          operator: 'StartsWith',
          values: 'AP',
        },
        {
          fieldName: 'clubCreator:clubCreatorSourceId',
          operator: 'Contains',
          values: [
            {
              label: 'Aha',
              value: appConfig.ahaUserId,
              avatarUrl: '/logo.png',
            },
          ],
        },
        {
          fieldName: 'officialType',
          operator: 'IsIn',
          values: [
            {
              label: 'Official',
              value: ClubOfficialType.Official,
            },
          ],
        },
      ],
    },
    {
      id: 'cbse10',
      label: 'CBSE 10',
      tooltip: t('filterChips.cbse10.tooltip', 'Show CBSE 10th clubs'),
      icon: (
        <SquareAvatar size={16} bgcolor="primary.dark">
          <Typography sx={styles.chipIconText} fontSize={4}>
            CBSE{'\n'}10
          </Typography>
        </SquareAvatar>
      ),
      conditions: [
        {
          fieldName: 'clubName',
          operator: 'StartsWith',
          values: 'CBSE 10',
        },
        {
          fieldName: 'clubCreator:clubCreatorSourceId',
          operator: 'Contains',
          values: [
            {
              label: 'Aha',
              value: appConfig.ahaUserId,
              avatarUrl: '/logo.png',
            },
          ],
        },
        {
          fieldName: 'officialType',
          operator: 'IsIn',
          values: [
            {
              label: 'Official',
              value: ClubOfficialType.Official,
            },
          ],
        },
      ],
    },
    {
      id: 'cbse12',
      label: 'CBSE 12th',
      tooltip: t('filterChips.cbse12.tooltip', 'Show CBSE 12th clubs'),
      icon: (
        <SquareAvatar size={16} bgcolor="#00C398">
          <Typography sx={styles.chipIconText} fontSize={4}>
            CBSE{'\n'}12
          </Typography>
        </SquareAvatar>
      ),
      conditions: [
        {
          fieldName: 'clubName',
          operator: 'StartsWith',
          values: 'CBSE 12th',
        },
        {
          fieldName: 'clubCreator:clubCreatorSourceId',
          operator: 'Contains',
          values: [
            {
              label: 'Aha',
              value: appConfig.ahaUserId,
              avatarUrl: '/logo.png',
            },
          ],
        },
        {
          fieldName: 'officialType',
          operator: 'IsIn',
          values: [
            {
              label: 'Official',
              value: ClubOfficialType.Official,
            },
          ],
        },
      ],
    },
  ];

  return (
    <BaseLayoutScrollContainer sx={styles.root} fullWidth>
      <ResponsivePageTitle
        icon={<MainAppsIcon width={40} height={40} />}
        titleText={t('Clubs')}
      />
      <Box sx={styles.container}>
        <QuickFilterChips
          filterType="club"
          sortType="club"
          items={filterChips}
          allItem={{
            icon: 'MainApps',
            tooltip: t('filterChips.all.tooltip', 'Show all clubs'),
          }}
          state={isError ? 'error' : isLoading ? 'loading' : 'default'}
        />
        <IaActionContextProvider availableActions={availableActions}>
          <IaLayouts layouts={layouts} />
        </IaActionContextProvider>
      </Box>
      <FloatingProfile
        anchorEl={creatorAnchorEl}
        onClose={() => setCreatorAnchorEl(null)}
        userId={currentCardItem?.metadata?.creatorId}
        currentClub={
          currentCardItem?.metadata
            ? {
                clubLogo: currentCardItem.metadata.clubLogo,
                clubName: currentCardItem.metadata.clubName,
              }
            : undefined
        }
      />
    </BaseLayoutScrollContainer>
  );
};

Home.getHead = () => <SEOHeader title="Discover Clubs" />;

Home.getLayout = (page) => (
  <RootLayout>
    <MainLayout toolComponent={<ClubToolbar />}>{page}</MainLayout>
  </RootLayout>
);

export default Home;
